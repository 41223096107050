import {
    IComponentAsProps,
    INavButtonProps,
    IconButton,
    Nav,
    Stack,
    StackItem,
} from "@fluentui/react";
import {makeStyles, mergeClasses, shorthands} from "@griffel/react";
import type {AppProps} from "next/app";
import Head from "next/head";
import Link from "next/link";
import {useRouter} from "next/router";
import {useCallback, useEffect, useState} from "react";
import {Connect, ErrorDialogProvider} from "../components";
import "../styles/globals.css";
import {Icons} from "../utils";
import {register as registerIcons} from "../utils/icons";

registerIcons();

const ROUTES = [
    {
        url: "/",
        icon: Icons.Home,
        name: "Home",
    },
    {
        url: "/device-info",
        icon: Icons.HeadsetVr,
        name: "Device Info",
    },
    /*{
        url: "/tcpip",
        icon: Icons.WifiSettings,
        name: "ADB over WiFi",
    },*/
    {
        url: "/install",
        icon: Icons.Box,
        name: "Install APK",
    },
    {
        url: "/file-manager",
        icon: Icons.Folder,
        name: "File Manager",
    },
    {
        url: "/power",
        icon: Icons.Power,
        name: "Power Menu",
    },
    {
        url: "/updater",
        icon: Icons.Update,
        name: "Update",
    },
    /*{
        url: "/framebuffer",
        icon: Icons.Camera,
        name: "Screen Capture",
    },*/
    {
        url: "/scrcpy",
        icon: Icons.Camera,
        name: "Screen Capture",
    },
    {
        url: "/shell",
        icon: Icons.WindowConsole,
        name: "Interactive Shell",
    },
    {
        url: "/logcat",
        icon: Icons.BookSearch,
        name: "Logcat",
    },
    /*{
        url: "/bug-report",
        icon: Icons.Bug,
        name: "Bug Report",
    },
    {
        url: "/packet-log",
        icon: Icons.TextGrammarError,
        name: "Packet Log",
    },*/
];

function NavLink({
                     link,
                     defaultRender: DefaultRender,
                     ...props
                 }: IComponentAsProps<INavButtonProps>) {
    if (!link) {
        return null;
    }

    return (
        <Link href={link.url} legacyBehavior passHref>
            <DefaultRender {...props} />
        </Link>
    );
}

const useClasses = makeStyles({
    titleContainer: {
        ...shorthands.borderBottom("1px", "solid", "rgb(243, 242, 241)"),
    },
    hidden: {
        display: "none",
    },
    title: {
        ...shorthands.padding("4px", "0"),
        fontSize: "20px",
        textAlign: "center",
    },
    leftColumn: {
        width: "270px",
        paddingRight: "8px",
        ...shorthands.borderRight("1px", "solid", "rgb(243, 242, 241)"),
        overflowY: "auto",
    },
});

function App({Component, pageProps}: AppProps) {
    const classes = useClasses();

    const [leftPanelVisible, setLeftPanelVisible] = useState(false);
    const toggleLeftPanel = useCallback(() => {
        setLeftPanelVisible((value) => !value);
    }, []);
    useEffect(() => {
        setLeftPanelVisible(innerWidth > 650);
    }, []);

    const router = useRouter();

    return (
        <ErrorDialogProvider>
            <Head>
                <link rel="manifest" href="/manifest.webmanifest"/>
                <meta name="application-name" content="Lynx Toolbox"/>
                <meta name="description"
                      content="Lynx Toolbox is an online tool to help you manage your Lynx headset. You can use it to install apps, update your device, or perform various other actions."/>
            </Head>

            <Stack verticalFill>
                <Stack
                    className={classes.titleContainer}
                    horizontal
                    verticalAlign="center"
                >
                    <IconButton
                        checked={leftPanelVisible}
                        title="Toggle Menu"
                        iconProps={{iconName: Icons.Navigation}}
                        onClick={toggleLeftPanel}
                    />

                    <StackItem grow>
                        <div className={classes.title}>Lynx Toolbox</div>
                    </StackItem>

                    <IconButton
                        iconProps={{iconName: "PersonFeedback"}}
                        title="Feedback"
                        as="a"
                        href="https://portal.lynx-r.com/support/thread/new/11"
                        target="_blank"
                    />
                </Stack>

                <Stack
                    grow
                    horizontal
                    verticalFill
                    disableShrink
                    styles={{
                        root: {
                            minHeight: 0,
                            overflow: "hidden",
                            lineHeight: "1.5",
                        },
                    }}
                >
                    <StackItem
                        className={mergeClasses(
                            classes.leftColumn,
                            !leftPanelVisible && classes.hidden
                        )}
                    >
                        <Connect/>

                        <Nav
                            groups={[
                                {
                                    links: ROUTES.map((route) => ({
                                        ...route,
                                        key: route.url,
                                    })),
                                },
                            ]}
                            linkAs={NavLink}
                            selectedKey={router.pathname}
                        />
                    </StackItem>

                    <StackItem grow styles={{root: {width: 0}}}>
                        <Component {...pageProps} />
                    </StackItem>
                </Stack>
            </Stack>
        </ErrorDialogProvider>
    );
}

export default App;
